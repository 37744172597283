



.Login {
  background-color: #f6f6f9;
  height: 100vh;
  padding-top: 120px;
}

.Login div {
  padding:60px;
  padding-left: 90px;
  padding-right: 90px;
  display: flex;
  flex-direction:column;
  align-items:center;
  max-width: 550px;
  margin: auto;
  background-color: #fff;
  border-radius: 3px;
  max-height: 500px;
}

.Dashboard {
  min-height: 100vh;
  padding-top: 140px;
  background-color: #5096ff;
}

.platform-div {
  max-width: 80%;
  margin:auto;
  margin-bottom:30px;
}

@media only screen and (min-width: 1024px) {
  .platform-div {
    max-height: 350px;
    max-width: 270px; 
  }
}