@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "League Spartan";
  src: url("https://fonts.gstatic.com/s/leaguespartan/v5/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPZA81bNA6MzbxG.woff");
}
body {
  margin: 0;
  font-family:'League Spartan';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
